import React, { Component } from 'react';

class AboutMe extends Component {
    render() {
        return (
            <div>
                <h2>ÜBER MICH</h2>
                <h3>Datenanalyse und maschinelles Lernen</h3>
                <p>
                    Ich bin Forschender im Bereich des maschinellen Lernens. <br />
                    Mein Themengebiet umfasst die Vorverarbeitung sowie Analye von hochfrequenten Datenströmen, wie sie
                    im IoT Kontext oft zu finden sind. <br />
                    Meine Veröffentlichungen finden Sie auf <a href="https://dblp.org/pid/239/7317.html">DBLP</a>.
                </p>

                <h3>ioBroker</h3>
                <p>
                    Ehrenamtlich bin ich einer der Core-Entwickler der Open Source Middleware Platform ioBroker. Ich
                    stelle mein Wissen Privatpersonen bei der Unterstützung individueller Probleme sowie der Erstellung
                    individueller Skripte zur Verfügung. Weitergehend biete ich Firmen, welche ihr Produkt in ioBroker
                    implementiert haben möchten, an einen vollwertigen Adapter zur Integration in das ioBroker Ökosystem
                    zu entwickeln.
                </p>

                <h3>Backend-Entwicklung</h3>
                <p>
                    Durch meine jahrelange Expertise im Bereich Nodejs und Typescript biete ich ebenfalls individuelle
                    Backend-Lösungen an. Auch für längerfristige Tätigkeiten in Form einer Festanstellung oder als
                    Freelancer bin ich grundsätzlich offen. Meine Schwerpunkte liegen hier neben Node.js und Typescript
                    in den folgenden Bereichen:
                    <ol>
                        <li>Redis</li>
                        <li>Anbindung von Rest APIs</li>
                        <li>JSON Schema</li>
                        <li>Entwicklung von REST-APIs (Express)</li>
                        <li>Dokumentation in Markdown/LaTeX</li>
                        <li>User Interfaces in React</li>
                        <li>Linux</li>
                        <li>Virtualisierung (Proxmox)</li>
                    </ol>
                    Eine Impression meiner Arbeiten im Open Source Bereich finden Sie auf{' '}
                    <a href="https://github.com/foxriver76">GitHub</a>.
                </p>

                <p>Bei Interesse kontaktieren Sie mich gerne.</p>
            </div>
        );
    }
}

export default AboutMe;
