import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Contact extends Component {
    render() {
        return (
            <div>
                <h2>SIE HABEN FRAGEN?</h2>
                <p>
                    Kontaktieren Sie mich gerne per <a href={'mailto:info@' + this.props.domain}>E-Mail</a>.
                </p>
            </div>
        );
    }
}

Contact.propTypes = {
    domain: PropTypes.string.isRequired
};

export default Contact;
