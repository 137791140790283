import React, { Component } from 'react';
import { Route, NavLink, HashRouter, Routes } from 'react-router-dom';
import Home from './home';
import Contact from './contact';
import AboutMe from './aboutme';
import Imprint from './imprint';
import Privacy from './privacy';
import { PHONE, DOMAIN, CITY, OWNER, STREET } from './lib/constants';

class Main extends Component {
    render() {
        return (
            <HashRouter>
                <div>
                    <h1>MORITZ HEUSINGER</h1>
                    <ul className="header">
                        <li>
                            <NavLink to="/">Startseite</NavLink>
                        </li>
                        <li>
                            <NavLink to="/aboutme">Über mich</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">Kontakt</NavLink>
                        </li>
                    </ul>
                    <div className="content">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/aboutme" element={<AboutMe />} />
                            <Route path="/contact" element={<Contact domain={DOMAIN} />} />
                            <Route
                                path="/imprint"
                                element={
                                    <Imprint domain={DOMAIN} phone={PHONE} street={STREET} owner={OWNER} city={CITY} />
                                }
                            />
                            <Route
                                path="/privacy"
                                element={<Privacy domain={DOMAIN} street={STREET} owner={OWNER} city={CITY} />}
                            />
                        </Routes>
                    </div>
                </div>
                <div className="footer">
                    <p>
                        &copy;Moritz Heusinger {new Date().getFullYear()} | <NavLink to="/imprint">Impressum</NavLink> |{' '}
                        <NavLink to="/privacy">Datenschutz</NavLink>
                    </p>
                </div>
            </HashRouter>
        );
    }
}

export default Main;
