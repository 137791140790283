import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                <h2>WILLKOMMEN</h2>
                <p>Meine Dienstleistungen umfassen:</p>
                <ol>
                    <li>Backend-Programmierung in Node.js und Typescript</li>
                    <li>Angewandte Datenanalyse und maschinelles Lernen</li>
                    <li>Programmierung und Erstellung von ioBroker Adaptern</li>
                    <li>Individuelle Beratung und Problemlösung zu ioBroker Themen</li>
                    <li>KNX Parametrierung</li>
                </ol>
                <p>
                    Im Großraum Würzburg kann vor Ort unterstützt werden, ansonsten biete ich alle Dienstleistungen auch
                    Remote an.
                </p>
                <p>Bei Interesse kontaktieren Sie mich gerne.</p>
            </div>
        );
    }
}

export default Home;
